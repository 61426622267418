export const depts={
    "Egyptian Art": {
        "color": 0x89cff2,
        "objects":[552485, 544216, 554336]},
    "Musical Instruments": {
        "color": 0xf7d700,
        "objects":[504073, 501473, 500685]},
    "Islamic Art": {
        "color": 0x8f89f7,
        "objects":[449314, 448452, 450960, 451444, 451358, 452057, 453670, 451908, 447355, 451283, 447163]},
    "Greek and Roman Art": {
        "color": 0xedfcb2,
        "objects":[254228, 254406, 247365, 254367, 240548, 245537, 241247, 241201, 252592, 240402, 253610, 248157]},
    "The American Wing": {
        "color": 0x46abf4,
        "objects":[4490, 8765, 8767, 8766, 3077, 14285, 20178, 10411, 6848, 11745, 12917, 11263, 11195, 16858, 19376, 11258, 7901, 701639, 19035]},
    "European Paintings": {
        "color": 0x4661f4,
        "objects":[817504, 436546, 437749, 437258, 643540, 436903, 436892, 435668, 437212, 436326, 437487, 437654, 435843]},
    "The Cloisters": {
        "color": 0xc7f7b1,
        "objects":[469900]},
    "Arms and Armor": {
    "color": 0xfa849c,
        "objects":[34010, 25728, 25668]},
    "Photographs": {
    "color": 0xffd57c,
        "objects":[286780, 261406, 286007, 283125, 684343]},
    "Medieval Art": {
    "color": 0xc9dff3,
        "objects":[474382]},
    "Asian Art": {
        "color": 0xF14300,
        "objects":[38367, 51363, 39154, 38643, 39059, 38074, 45457, 75355, 56499, 73399, 72809, 65708, 45239, 54936, 54983, 54033, 54049, 70352, 63786, 45017, 57208, 75301, 670983, 45254, 59937, 72543, 57342, 42435, 55948, 37017, 45474, 45656, 53404, 45477, 671050, 40508]},
    "Robert Lehman Collection": {
    "color": 0xfff398,
        "objects":[460950]},
    "Drawings and Prints": {
    "color": 0xffd57c,
        "objects":[821225, 415334, 735421, 736068, 418347, 738077, 359151, 346335, 359899, 810943, 682986, 682994, 682989, 683011, 683016, 682992, 682993, 682990, 682991, 683014, 683015, 683008, 682987, 683017, 683009, 682988, 683006, 683007, 683013, 683005, 683010, 683012, 682973, 822567, 822566, 382932, 387596, 390472, 399244, 385968, 385967, 386348, 645149, 764581, 834126]},
    "Costume Institute": {
        "color": 0xc4c0fc,
        "objects":[98274, 98275, 158918, 156488, 156463, 156464]},
    "European Sculpture and Decorative Arts": {
    "color": 0x4661f4,
        "objects":[204632, 188263, 207039, 206728, 203391, 203454, 203490, 203479, 203473, 203524, 203523, 203407, 203425, 203427, 203409, 203450, 200378, 203392, 203399, 203489, 203460, 203472, 203458, 203452, 203486, 203476, 203453, 203484, 203491, 203471, 203494, 203499, 203463, 203465, 203462, 203482, 203488, 203487, 203483, 203459, 203496, 203485, 203468, 203464, 203474, 203497, 203461, 203503, 203502, 203501, 203509, 203527, 203505, 203520, 203526, 203504, 203518, 203506, 203519, 203510, 203395, 203404, 203416, 203408, 203424, 203419, 203446, 203423, 203436, 203410, 203449, 203413, 203411, 203418, 203422, 203415, 203421, 203429, 203403, 203394, 203396, 203397, 203400, 203451, 203470, 203467, 203466, 203475, 203469, 203477, 203498, 203500, 203456, 203495, 203481, 203478, 203455, 203457, 203480, 203513, 203525, 203516, 203507, 203515, 203514, 203517, 203521, 203511, 203512, 203522, 203398, 203402, 203433, 203417, 203401, 203405, 203440, 203431, 203437, 203420, 203414, 203443, 203428, 203432, 203447, 203426, 203444, 203442, 203445, 203412, 203438, 203441, 203430, 203448, 203439, 203434, 203435, 210316, 203508, 203406, 202743, 202755, 202752, 202756, 202754, 202753, 202849, 202825, 202919, 202920, 202918, 202917, 200143, 200142, 202762, 202763, 202383, 206705, 207394, 228208, 203393, 198952]}}